import { Fragment, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useSurveyData } from "../hooks/use-survey-data";
import { InputRenderer } from "./survey-renderer";

export const LinkGenerator = () => {
  const [data, setData] = useState<{[key: string]: string | string[]}>({});
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { surveyName } = useParams();

  const { surveyData } = useSurveyData(surveyName);

  const link = useMemo(() => {
    const filteredData : {[key: string]: string | string[]} = {}

    for(const key in data) {
      if(selectedKeys.includes(key)) filteredData[key] = data[key];
    }

    const prefill = btoa(JSON.stringify(filteredData));
      return `https://survey.nimey.de/survey/${surveyName}?prefill=${prefill}`;

  }, [data, selectedKeys, surveyName]);

  if(!surveyData) return <Fragment />;

  return (
    <div>
      <h1>{surveyData.title}</h1>
      <ul>
        {surveyData.pages.map((page, idx) => (
          <li key={`page-${idx}`}>
            <p>Seite { idx + 1 }</p>
            <ul>
              {page.questions.map(question => (
                <li key={question.id}>
                  <label>
                    <input
                      type='checkbox'
                      checked={selectedKeys.includes(question.id)}
                      onChange={(e) => {
                        const isSelected = selectedKeys.includes(question.id);
                        if(e.target.checked && !isSelected) setSelectedKeys([...selectedKeys, question.id]);
                        else if (!e.target.checked && isSelected) setSelectedKeys(selectedKeys.filter(s => s !== question.id));
                      }}
                    />
                    {question.id} / {question.title}
                  </label>
                  {selectedKeys.includes(question.id) ? (
                    <InputRenderer
                      question={question}
                      data={data}
                      prefillKeys={[]}
                      value={data[question.id] || ''}
                      onChange={(value: string | string[]) => {
                        setData({...data, [question.id]: value})
                      }} 
                    />
                  ) : ''}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <pre>{JSON.stringify({selectedKeys, data}, null, 2)}</pre>
      <p><strong><a href={link}>{link}</a></strong></p>
    </div>
  );
}