import { Fragment, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { replaceVars, TitleRenderer } from './survey-renderer';
import { SurveyQuestion, useSurveyData } from '../hooks/use-survey-data';
import useLocalStorageState from 'use-local-storage-state';
import { useSubmitSurvey } from '../hooks/use-submit-survey';

import { Button } from '@nimey/react-ui'

import styles from './survey-renderer.module.scss';
import ErrorBoundary, { ResetSurveyAndReload } from './error-boundary';

export const QuestionRenderer = ({question, data}: {question: SurveyQuestion, data: {[key: string]: string | string[]}}) => {

  const { renderedValue , show } = useMemo(() => {
    let value = data[question.id];
    switch(question.inputType) {
      case 'choose':
        value = question.settings?.options ? (question.settings?.options[value as string] || value) : value;
        break;
      case 'multi-select':
        value = (Array.isArray(value) ? value : [value])
          .map(v => question.settings?.options ? (question.settings?.options[v as string] || v) : v);
        break;
      case 'date':
        try {
          value = new Intl.DateTimeFormat('de-DE', {dateStyle: 'full'}).format(new Date(value as string));
        } catch(e) {}
        break;
    }

    if(Array.isArray(value)) {
      value = value.map((v) => replaceVars(data ,v));
    } else value = replaceVars(data, value);

    if(value.length === 0) value = 'Keine Angaben'

    const show = !question.condition ? true : eval(`${question.condition}`);

    return { renderedValue: value, show };
  }, [question, data]);

  if(!show) return <Fragment />

  return (
    <div className={styles['question-wrapper']}>
      <div><TitleRenderer {...{data, question, prefillKeys: [], value: '', onChange: (e) => ''}} /></div>
      <div style={{whiteSpace: 'pre-wrap', margin: '.75em 0'}}>
        {Array.isArray(renderedValue) ? (
          <ul>
            {renderedValue.map(v => <li key={v}>{v}</li>)}
          </ul>
        ) : <span>{renderedValue}</span>}
      </div>
    </div>
  );
}

const Spinner = () => <div className={styles['spinner']}><div></div><div></div><div></div><div></div></div>

const SurveySummaryInner = () => {
  const { surveyName } = useParams();
  const [ params ] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { surveyData } = useSurveyData(surveyName);

  const [ data ] = useLocalStorageState<{[key: string]: string | string[]}>(`survey-${surveyName}`);

  const submitSurvey = useSubmitSurvey(surveyName, data);

  if(!surveyData || !data) return <Fragment />;

  return (
    <div>
      {loading ? (
        <div className={styles.loading}>
          <Spinner />
        </div>
      ) : ''}
      <h1>{surveyData.title} - Zusammenfassung</h1>
      {surveyData.pages.map((page, idx) => (
        <div key={`page-${idx}`}>
          {page.questions.filter(question => !['display-text'].includes(question.inputType)).map(question => (
            <QuestionRenderer key={question.id} {...{question, data}} />
          ))}
        </div>
      ))}
      <div className={styles['button-row']}>
        <Button disabled={loading} onClick={() => {
          navigate(`/survey/${surveyName}${params.get('prefill') ? `?prefill=${params.get('prefill')}` : ''}`)
        }}>Eingaben ändern</Button>

        <Button primary disabled={loading} onClick={() => {
          setLoading(true);
          submitSurvey();
        }}>{surveyData.submitButtonText}</Button>
      </div>

      <div>
        <ResetSurveyAndReload surveyName={surveyName} />
      </div>
    </div>
  );
}

export const SurveySummary = () => {
  const { surveyName } = useParams();
  return (
    <ErrorBoundary message='foo' surveyName={surveyName}>
      <SurveySummaryInner />
    </ErrorBoundary>
  );
}