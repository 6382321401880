import ReactMarkdown from 'react-markdown';
import { SurveyData, SurveyQuestion } from '../hooks/use-survey-data';
import { ChangeEvent, Fragment, useEffect, useMemo, useState } from 'react';

import styles from './survey-renderer.module.scss';
import useLocalStorageState from 'use-local-storage-state';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Button, DateInput, TextareaInput, TextInput } from '@nimey/react-ui';

interface QuestionRendereType {
  id?: string;
  data: {[key: string]: string | string[]};
  question: SurveyQuestion;
  value: string | string[];
  prefillKeys: string[];
  onChange: (value: string | string[]) => void;
}

export const InputTextRenderer = ({question, value, onChange, prefillKeys}: QuestionRendereType) => {
  return (
    <TextInput
      placeholder={question.placeholder || ''}
      name={question.id}
      id={question.id}
      {...{required: question.required}}
      value={value as string}
      disabled={prefillKeys.includes(question.id)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      disablePlaceholderAnimation={true}
    />
  )
}

export const InputDateRenderer = ({question, value, onChange, prefillKeys}: QuestionRendereType) => {
  return (
    <DateInput
      placeholder={question.placeholder || ''}
      name={question.id}
      id={question.id}
      {...{required: question.required}}
      value={value as string}
      disabled={prefillKeys.includes(question.id)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      disablePlaceholderAnimation={true}
    />
  )
}

export const InputTextareaRenderer = ({question, value, prefillKeys, onChange}: QuestionRendereType) => {
  return (
    <TextareaInput
      placeholder={question.placeholder || ''}
      name={question.id}
      id={question.id}
      {...{required: question.required}}
      value={value as string}
      disabled={prefillKeys.includes(question.id)}
      onChange={(e) => onChange(e.target.value)}
      disablePlaceholderAnimation={true}
    />
  )
}

const InputChooseOptionRenderer = ({id, question, option, value, data, onChange}: QuestionRendereType & { option: string}) => {
  const image = useMemo(() => {
    if (!question.settings?.images) return false;
    const image = question.settings.images[`option-${option}`];
    if (!image) return false;

    return image;
  }, [question, option])

  return (
    <div className={`${styles['option']} ${image ? styles['with-image'] : styles['no-image']} ${value.includes(option) ? styles['selected'] : ''}`}>

      <label>
        <input 
          style={{margin: '0 .25em'}}
          type="radio"
          name={id ? `${id}-${question.id}` : question.id}
          id={`${question.id}-${option}`}
          value={option}
          {...{required: question.required}}
          checked={option === value}
          onChange={() => onChange(option)}
        />
        {image ? (
          <img src={image.src} alt='' />
        ) : ''}
        <TextWithVarsRenderer {...{
          data,
          text: question?.settings?.options? question?.settings?.options[option] : ''
        }} />
      </label>
    </div>
  );
}

export const InputChooseRenderer = ({id, question, value, onChange, data, prefillKeys}: QuestionRendereType) => {
  return (
    <div className={styles['choose']}>
      {Object.keys(question?.settings?.options || {}).map(option => (
        <InputChooseOptionRenderer key={option} {...{id, question, value, onChange, data, option, prefillKeys}} />
      ))}
    </div>
  )
}

const InputMultiChooseChoiceRenderer = ({option, question, value, onChange, data}: {option: string, question: SurveyQuestion, value: string |string[], onChange: (v: string| string[]) => void, data: {[key: string]: string | string[]}}) => {
  const image = useMemo(() => {
    if (!question.settings?.images) return false;
    const image = question.settings.images[`option-${option}`];
    if (!image) return false;

    return image;
  }, [question])


  return (
    <div className={`${styles['option']} ${image ? styles['with-image'] : styles['no-image']} ${value.includes(option) ? styles['selected'] : ''}`}>
      <label>
        <input
          type="checkbox"
          name={question.id}
          id={`${question.id}-${option}`}
          checked={value.includes(option)}
          onChange={(e) => {
            if(!e.target.checked && value.includes(option)) onChange((value as string[]).filter(v => v !== option))
            else if(e.target.checked && !value.includes(option)) onChange([...(value as string[]), option])
          }}
        />
        {image ? (
          <img src={image.src} />
        ) : ''}
        <TextWithVarsRenderer {...{
          data,
          text: question?.settings?.options? question?.settings?.options[option] : ''
        }} />
      </label>
    </div>
  )
}

export const InputMultiChooseRenderer = ({question, value, data, onChange}: QuestionRendereType) => {
  return (
    <div className={styles['multi']}>
      {Object.keys(question?.settings?.options || {}).map(option => (
        <Fragment key={option}>
          <InputMultiChooseChoiceRenderer {...{question, value, onChange, option, data}} />
        </Fragment>
      ))}
      
    </div>
  )
}

export const InputRenderer = ({id, data, question, value, prefillKeys, onChange}: QuestionRendereType) => {
  if(question.inputType === 'display-text') return <TextWithVarsRenderer {...{data, text: question?.settings?.text || ''}} />
  if(question.inputType === 'text') return <InputTextRenderer {...{prefillKeys, question, value, onChange, data}} />
  if(question.inputType === 'date') return <InputDateRenderer {...{prefillKeys, question, value, onChange, data}} />
  if(question.inputType === 'textarea') return <InputTextareaRenderer {...{prefillKeys, question, value, onChange, data}} />
  if(question.inputType === 'choose') return <InputChooseRenderer {...{id, prefillKeys, question, value, onChange, data}} />
  if(question.inputType === 'multi-select') return <InputMultiChooseRenderer {...{prefillKeys, question, value, onChange, data}} />

  
  
  return <Fragment />
}


export const TitleRenderer = ({data, question}: QuestionRendereType) => {
  return (
    <TextWithVarsRenderer
      {...{
        data,
        text: question.required ? `${question.title} *` : question.title
      }}
    />
  );
}

export const replaceVars = (data: {[key: string]: string | string[]}, text: string) => {
  let newText = text;
  let match;
  let counter = 0;
  while(match = newText.match(/(##[a-z0-9._\/ |]+?##)/i)) {
    const varInput = match[0].replaceAll('#', '').split('|');
    const varValue = eval(varInput[0]) || varInput[1] || '';
    newText = newText.substr(0, match.index) + varValue + newText.substr(match.index as number + match[0].length)
    counter++;
    if(counter > 50) break;
  }

  return newText;
}

export const TextWithVarsRenderer = ({data, text}: {data: {[key: string]: string | string[]}, text: string}) =>  {
  const replaced = useMemo(() => {
    return replaceVars(data, text);
  }, [data, text]);

  return <ReactMarkdown children={replaced} />
}

const useCurrentPage = () => {
  const { page } = useParams();

  return useMemo(() => page ? parseInt(page) : 0, [page]);
}


export const SurveyRenderer = ({ survey }: {survey: SurveyData}) => {
  const navigate = useNavigate();
  const [data, setData] = useLocalStorageState<{[key: string]: string | string[]}>(`survey-${survey.name}`,{
    defaultValue: () => {
      const result : {[key: string]: string | string[]} = {};
      for(const page of survey.pages) {
        for(const question of page.questions) {
          if(question.inputType === 'multi-select') {
            result[question.id] = [];
          } else result[question.id] = '';
        }
      }
      
      return result;
    }
  });
  const currentPage = useCurrentPage();
  const [ prefillKeys, setPrefillKeys ] = useState<string[]>([]);

  const [params] = useSearchParams();

  useEffect(() =>  {
    window.scrollTo({top: 0, behavior: 'auto'});
  }, [currentPage])
  
  useEffect(() =>  {
    if(params.get('prefill')) {
      try {
        const prefillData = JSON.parse(atob(params.get('prefill') as string)) as {[key: string]: string | string[]};
        setPrefillKeys(Object.keys(prefillData));
        setData(d => ({...d, ...prefillData}));
      } catch(e) {}
    }
  }, [params])

  const questionFilter = (question: SurveyQuestion) => {
    if(!question.condition) return true;

    try {
      const result = eval(`${question.condition}`)
      return result;
    } catch(e) {
      console.log(e);
      throw new Error(`Fehler in der Bedingung der Frage ${question.id}`);
    }
  }
  return (
    <div>
      <h1>{survey.title}</h1>
      {currentPage === 0 ? (<TextWithVarsRenderer {...{data, text: survey.description}} />) : ''}
      <form method='POST'
        onSubmit={(e) => {
          e.preventDefault();
          if(currentPage >= (survey.pages.length - 1)) {
            navigate(`/survey/${survey.name}/summary${params.get('prefill') ? `?prefill=${params.get('prefill')}` : ''}`);
            return;
          }
          navigate(`/survey/${survey.name}/${currentPage + 1}${params.get('prefill') ? `?prefill=${params.get('prefill')}` : ''}`);
        }}
      >
        {(survey.pages[currentPage]).questions.filter(questionFilter).map(question => (
          <div key={question.id} className={styles['question-wrapper']}>
            <div className={styles['question-title']}>
              <label htmlFor={question.id}>
                <TitleRenderer {...{data, question, prefillKeys, value: data[question.id], onChange: (e) => ''}} />
              </label>
            </div>
            <InputRenderer question={question} data={data} prefillKeys={prefillKeys} value={data[question.id]} onChange={(value: string | string[]) => {
              setData({...data, [question.id]: value})
            }} />
          </div>
        ))}
        <div className={styles['button-row']}>
        {currentPage > 0 ? (
          <Button type='button' onClick={(e) => {
            e.preventDefault();
            navigate(`/survey/${survey.name}/${currentPage - 1}${params.get('prefill') ? `?prefill=${params.get('prefill')}` : ''}`);
          }}>Zurück</Button>
        ) : ''}
          
          <Button primary type="submit">Weiter</Button>
        </div>
      </form>
    </div>
  );
}