import { Fragment, useId, useMemo, useState } from "react";
import { useParams } from "react-router";
import { SurveyQuestion, useSurveyData } from "../hooks/use-survey-data";
import { InputRenderer } from "./survey-renderer";
import { Button } from '@nimey/react-ui';

import styles from './multi-link-generator.module.scss';

export const MultiLinkGenerator = () => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { surveyName } = useParams();
  const [rows, setRows] = useState<{[key: string]: string | string[]}[]>([]);

  const { surveyData } = useSurveyData(surveyName);

  if(!surveyData) return <Fragment />;

  return (
    <div>
      <h1>{surveyData.title}</h1>
      <ul>
        {surveyData.pages.map((page, idx) => (
          <li key={`page-${idx}`}>
            <p>Seite { idx + 1 }</p>
            <ul>
              {page.questions.map(question => (
                <li key={question.id}>
                  <label>
                    <input
                      type='checkbox'
                      checked={selectedKeys.includes(question.id)}
                      onChange={(e) => {
                        const isSelected = selectedKeys.includes(question.id);
                        if(e.target.checked && !isSelected) setSelectedKeys([...selectedKeys, question.id]);
                        else if (!e.target.checked && isSelected) setSelectedKeys(selectedKeys.filter(s => s !== question.id));
                      }}
                    />
                    {question.id} / {question.title}
                  </label>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      <table className={styles.table}>
        <tr>
          {selectedKeys.map(key => <th key={key}>{key}</th>)}
          <th>Actions</th>
          <th>Link</th>
        </tr>
        {rows.map((row, idx) => <RowRenderer {...{
          surveyName,
          selectedKeys,
          row,
          setRow: (row: {[key: string]: string | string[]}) => setRows((rows) => {
            const newRows = [...rows];
            newRows[idx] = {...row};
            return newRows;
          }),
          removeRow: () => setRows((rows) => rows.filter((v, i) => i !== idx))
        }} />)}
        <tr>
          <td colSpan={selectedKeys.length+2}><Button onClick={() => setRows(rows => [...rows, {}])}>Add Row</Button></td>
        </tr>
      </table>
    </div>
  );
}

const RowRenderer = (props: {surveyName?: string, selectedKeys: string[], row:{[key: string]: string | string[]}, setRow: any, removeRow: () => any}) => {
  const { surveyData } = useSurveyData(props.surveyName);

  const id = useId();

  const questions = useMemo(() => {
    const newQuestions: {[id: string]: SurveyQuestion} = {};
    surveyData?.pages.forEach(page => page.questions.forEach(q => {
      newQuestions[q.id] = q;
    }))

    return newQuestions;
  }, [surveyData])

  const link = useMemo(() => {
    const filteredData : {[key: string]: string | string[]} = {}

    for(const key in props.row) {
      if(props.selectedKeys.includes(key)) filteredData[key] = props.row[key];
    }

    const prefill = btoa(JSON.stringify(filteredData));
      return `https://survey.nimey.de/survey/${props.surveyName}?prefill=${prefill}`;

  }, [props.row, props.selectedKeys, props.surveyName]);

  if(Object.keys(questions).length === 0) return <Fragment />

  

  return (
    <tr>
      {props.selectedKeys.map((key) => (
        <td key={key}>
          <InputRenderer
            id={id.replace(':', '')}
            question={questions[key]}
            data={props.row}
            prefillKeys={[]}
            value={props.row[key] || ''}
            onChange={(value: string | string[]) => {
              props.setRow(({...props.row, [key]: value}))
            }} 
          />
        </td>
      ))}
      <td><Button onClick={() => props.removeRow()}>x</Button></td>
      <td><a href={link}>Link</a></td>
    </tr>
  );
}