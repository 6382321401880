import { Button } from "@nimey/react-ui";
import React, { Component, ErrorInfo, Fragment, ReactNode } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export const ResetSurveyAndReload = ({ surveyName }:{surveyName: string | undefined}) => {

  const [ params ] = useSearchParams();
  if(!surveyName) return <Fragment />;
  
  return (
    <a
      href='#'
      style={{color: 'black'}}
      onClick={(e) => {
        e.preventDefault();
        localStorage.removeItem(`survey-${surveyName}`)
        window.location.replace(`/survey/${surveyName}${params.get('prefill') ? `?prefill=${params.get('prefill')}` : ''}`);
      }}
    >Fragebogen zurücksetzen und erneut beginnen</a>
  );
}

interface Props {
  children?: ReactNode;
  message?: string;
  surveyName?: string;
}

interface State {
  hasError: boolean;
  error?: Error;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({...this.state, error})
  }

  public render() {
    
    if (this.state.hasError) {
      console.log(this)
      return (
        <div>
          <h1>{this.props.message || 'Sorry.. there was an error'}</h1>
          <p>{this.state.error ? (this.state.error as Error).toString() : ''}</p>
          <ResetSurveyAndReload surveyName={this.props.surveyName} />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;