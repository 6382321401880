import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { SurveyRenderer } from './survey-renderer';
import { useSurveyData } from '../hooks/use-survey-data';
import ErrorBoundary from './error-boundary';
import { Loading } from './loading';
import { NotFound } from './not-found';

export const Survey = () => { 
  const { surveyName } = useParams();

  const { surveyData, loading } = useSurveyData(surveyName);

  if(!surveyData && loading) return <Loading />;
  if(!surveyData && !loading) return <NotFound />;

  if(!surveyData) return <Fragment />

  return (
    <ErrorBoundary message='Entschuldigung. Leider gab es einen Fehler. Bitte versuche es später erneut.' surveyName={surveyName}>
      <SurveyRenderer survey={surveyData} />
    </ErrorBoundary>
  );
}