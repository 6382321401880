import { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { useSurveyData } from '../hooks/use-survey-data';

export const SurveyFailure = () => {
  const { surveyName } = useParams();
  

  const { surveyData } = useSurveyData(surveyName);

  if(!surveyData) return <Fragment />;

  return (
    <div>
      <h1>{surveyData.title}</h1>
      <ReactMarkdown children={surveyData.failureText} />
    </div>
  );
}