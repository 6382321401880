import { useNavigate } from "react-router"

const API_HOST = '';

export const useSubmitSurvey = (surveyName: string | undefined, data: {[key: string]: string | string[]} | undefined) => {
  const navigate = useNavigate();
  return async () => {
    const response = await fetch(`${API_HOST}/api/survey/${surveyName}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();

    if(responseData.success) {
      navigate(`/survey/${surveyName}/success`);
    } else {
      navigate(`/survey/${surveyName}/failure`);
    }
  }
}