import { useEffect, useState } from 'react';

export interface SurveyQuestion {
  id: string;
  title: string;
  inputType: 'text' | 'choose' | 'multi-select' | 'textarea' | 'display-text' | 'date';
  required: boolean;
  placeholder?: string;
  condition?: string;
  settings?: {
    options?: {[key: string]: string};
    images: {
      [key: string]: {
        src: string
      }
    };
    text?: string;
  }
}

export interface SurveyData {
  id: string;
  name: string;
  title: string;
  description: string;
  submitButtonText: string;
  successText: string;
  failureText: string;
  pages: {
    questions: SurveyQuestion[];
  }[]
}

const API_HOST = ''

export const useSurveyData = (surveyName: string | undefined) => {
  const [ surveyData, setSurveyData ] = useState<SurveyData | undefined>();
  const [ loading, setLoading ] = useState<boolean>(true);
  
  useEffect(() => {
    if(!surveyName) return;
    setLoading(true);
    fetch(`${API_HOST}/api/survey/${surveyName}`).then(async (response) => {
      if(response.status === 404)  {
        setSurveyData(undefined);
        setLoading(false);
        return;
      }
      const data = await response.json();
      setSurveyData({
        name: surveyName,
        ...data
      });
      setLoading(false);
    })
  }, [surveyName])

  return { surveyData, loading };
}