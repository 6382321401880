import './app.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Survey } from './components/survey';
import { SurveySummary } from './components/survey-summary';
import { SurveySuccess } from './components/survey-success';
import { SurveyFailure } from './components/survey-failure';
import { LinkGenerator } from './components/link-generator';
import ErrorBoundary from './components/error-boundary';
import { MultiLinkGenerator } from './components/multi-link-generator';

function App() {
  return (
    <ErrorBoundary message={'Entschuldigung. Leider gab es einen Fehler. Bitte versuche es später erneut.'}>
      <BrowserRouter>
        <Routes>
          <Route path='/survey/:surveyName/__link-generator' Component={LinkGenerator} />
          <Route path='/survey/:surveyName/__multi-link-generator' Component={MultiLinkGenerator} />
          <Route path='/survey/:surveyName/failure' Component={SurveyFailure} />
          <Route path='/survey/:surveyName/success' Component={SurveySuccess} />
          <Route path='/survey/:surveyName/summary' Component={SurveySummary} />
          <Route path='/survey/:surveyName/:page' Component={Survey} />
          <Route path='/survey/:surveyName' Component={Survey} />
          <Route path='*' element={<center>Diese Seite wurde nicht gefunden</center>} />
        </Routes>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
